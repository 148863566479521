import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import * as Toast from "@radix-ui/react-toast";

import { BiCheck, BiChevronDown, BiSave, BiSend } from "react-icons/bi";
import { BsPatchCheckFill } from "react-icons/bs";
import { FiChevronLeft } from "react-icons/fi";
import { HiOutlineDownload } from "react-icons/hi";

import { useSpring } from "@react-spring/web";
import { DocumentViewer } from "../components/DocumentViewer";
import { FinalDocumentChat } from "../components/FinalDocumentChat";

import jsPDF from "jspdf";
import {
  deleteDocumentDraftAPI,
  getDocumentPaidStatusAPI,
  getDocumentPaymentLinkAPI,
  getSignatureStatusAPI,
  saveDocumentOverviewAPI,
  saveDraftDocumentAPI,
  sendForSignatureAPI,
  submitForReviewAPI,
  updateName,
  upgradePlanAPI,
} from "../api/apiCalls";
import { Modal } from "../components/Modal";
import { Tooltip } from "../components/Tooltip";
import {
  downloadFile,
  formatDate,
  returnFormattedDocumentsArray,
} from "../functions";
import { updateDocument } from "../redux/actions/documentActions";

import { Document, Font, Page } from "@react-pdf/renderer";
import { saveAs } from "file-saver";

import { IoClose, IoWarning } from "react-icons/io5";
import MyCustomFont from "../fonts/Inter.ttf";

import "../toast.css";

import { AiFillEdit, AiFillInfoCircle } from "react-icons/ai";
import { Oval } from "react-loader-spinner";

import axios from "axios";
import { MdViewDay } from "react-icons/md";
import { FinalDocumentContext } from "../components/FinalDocumentChat/FinalDocumentContext";

import RadixModal from "../components/Modal/RadixModal";
import { ResetIcon } from "@radix-ui/react-icons";

Font.register({ family: "Inter", src: MyCustomFont });

const handleDownload = (htmlBody) => {
  const doc = (
    <Document>
      <Page>
        {/* Your HTML content */}
        <div style={{ fontFamily: "Inter" }}>{htmlBody}</div>
      </Page>
    </Document>
  );

  const asBlob = new Blob([doc], { type: "application/pdf" });
  saveAs(asBlob, "my-document.pdf");
};

export const FinalDocument = () => {
  const { id, versionId } = useParams();

  // const { documentDetails } = useSelector((state) => state.stepper);
  const {
    documentInfo,
    signatures,
    allDocuments,
    documentElements,
    unsavedChanges,
  } = useSelector((state) => state.document);

  const user = useSelector((state) => state.user);

  const [chatInfo, setChatInfo] = useState({
    type: {
      label: "chatWithPilot",
      text: "Pilot",
    },
  });

  const [showChat, setShowChat] = useState(false);
  const [chatSectionProps, setChatSectionProps] = useSpring(() => ({
    opacity: 1,
  }));

  const navigate = useNavigate();

  const toggleChat = (value, chatType) => {
    switch (value) {
      case "open":
        if (chatType.label === "overview") {
          setChatInfo({
            type: chatType,
            data: {
              question: "Give me a quick overview of this document",
              autoSend: true,
            },
          });
        } else {
          setChatInfo({
            type: chatType,
          });
        }

        setShowChat(true);
        setChatSectionProps({
          from: {
            opacity: 0,
          },
          to: {
            opacity: 1,
          },
        });
        break;
      case "close":
        setChatSectionProps({
          from: {
            opacity: 1,
          },
          to: {
            opacity: 0,
          },
        });

        setTimeout(() => {
          setShowChat(false);
        }, 200);
        break;
      default:
        break;
    }
  };

  const [deltaPosition, setDeltaPosition] = useState({});

  const handleDrag = (e, ui) => {
    const { x, y } = deltaPosition;
    setDeltaPosition({
      x: x + ui.deltaX,
      y: y + ui.deltaY,
    });
  };

  const onSignatureDragStart = (event, data) => {
    event.dataTransfer.setData("data", data);
  };

  const allSignaturesFinalised = () => {
    // // // //"signatures: ", documentInfo?.signature);
    if (documentInfo) {
      if (documentInfo?.signature && documentInfo?.signature.signers) {
        const signers = documentInfo?.signature.signers
          ? documentInfo?.signature.signers.filter(
              (signature) => signature.name
            )
          : [];

        if (signers.length !== 0) {
          return true;
        } else return false;
      } else if (signatures.length !== 0) {
        const isFinalised = signatures.filter(
          (signature) =>
            signature.details &&
            signature.details.finalised &&
            signature.details.finalised === true
        );

        if (isFinalised.length !== signatures.length) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  const [openSendForSignatureModal, setOpenSendForSignatureModal] =
    useState(false);
  const [signatureLoading, setSignatureLoading] = useState(false);

  const dispatch = useDispatch();

  const convertStringToHTML = (htmlString) => {
    const parser = new DOMParser();
    const html = parser.parseFromString(htmlString, "text/html");

    return html.body;
  };

  const formatDocumentForSigning = () => {
    return new Promise((resolve, reject) => {
      const parentDiv = atob(
        decodeURIComponent(
          documentInfo?.document_drafts[
            documentInfo?.document_drafts?.length - 1
          ]
        )
      );
      const parser = new DOMParser();
      const html = parser.parseFromString(parentDiv, "text/html");

      const clonedDiv = html.body.querySelector("#document-builder");
      const childDivs = clonedDiv.querySelectorAll(".finalDocumentViewer");

      childDivs.forEach((childDiv) => {
        childDiv.style.setProperty("box-shadow", "none");
        childDiv.style.setProperty("margin-bottom", "0px");
        childDiv.style.setProperty("border", "none");
        childDiv.style.setProperty("border-radius", "none");
        childDiv.style.setProperty("font-family", "Helvetica, sans-serif");
      });

      const htmlString = clonedDiv.outerHTML;
      const _document = clonedDiv;
      const doc = new jsPDF("p", "px", [1162, 820], true);

      doc.setFont("Inter", "normal");

      // doc.addFileToVFS("Inter.ttf", InterFont);
      // doc.addFont("Inter.ttf", "Inter", "normal");

      if (_document) {
        doc.html(_document, {
          callback: async function (doc) {
            const output = await doc.output("datauristring");
            var base64result = output.split(",")[1];
            resolve(base64result);
          },
        });
      } else {
        reject("Failed to generate PDF.");
      }
    });
  };

  const sendForSignature = async () => {
    try {
      setSignatureLoading(true);

      const formattedDocumentViewerBase64String =
        await formatDocumentForSigning();

      if (formattedDocumentViewerBase64String) {
        const response = await sendForSignatureAPI({
          draft: id,
          base64Document: formattedDocumentViewerBase64String,
        });

        if (response.data) {
          setSignatureLoading(false);
          dispatch(
            updateDocument({
              documentInfo: {
                ...documentInfo,
                signature: {
                  ...documentInfo?.signature,
                  sentForSignature: true,
                },
              },
            })
          );
        }
      }
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const SendForSignature = ({ showTooltip }) => {
    if (showTooltip) {
      return (
        <Tooltip
          text={
            documentInfo?.signature?.sentForSignature
              ? "Document is already sent for signature"
              : !allSignaturesFinalised() &&
                "Add signatures to start signing the document."
          }
        >
          <button
            className={`saveButton signButton ${
              (documentInfo?.signature?.sentForSignature ||
                !allSignaturesFinalised()) &&
              "disabledButton"
            }`}
            disabled={
              documentInfo?.signature?.sentForSignature ||
              !allSignaturesFinalised()
            }
            onClick={() =>
              setOpenSendForSignatureModal(!openSendForSignatureModal)
            }
          >
            <BiSend className="saveButtonIcon" />
            <div className="saveButtonText">Send for Signature</div>
          </button>
        </Tooltip>
      );
    } else {
      return (
        <button
          className={`saveButton signButton ${
            (documentInfo?.signature?.sentForSignature ||
              !allSignaturesFinalised()) &&
            "disabledButton"
          }`}
          onClick={() =>
            setOpenSendForSignatureModal(!openSendForSignatureModal)
          }
        >
          <BiSend className="saveButtonIcon" />
          <div className="saveButtonText">Send for Signature</div>
        </button>
      );
    }
  };

  const [signatureStatus, setSignatureStatus] = useState([]);
  const [documentNameInput, setDocumentNameInput] = useState("");

  const getSignatureStatus = async () => {
    if (documentInfo && documentInfo?.signature?.sentForSignature) {
      const response = await getSignatureStatusAPI(id);

      if (response.data) {
        setSignatureStatus(response.data);
      }
    }
  };

  // useEffect(() => {
  //   if (documentInfo && documentInfo?.signature?.sentForSignature) {
  //     getSignatureStatus();
  //   }
  // }, [documentInfo]);

  const findAndAddDocumentInfo = () => {
    if (documentInfo && documentInfo?.name) {
      setDocumentNameInput(documentInfo?.name);
    }

    if (documentInfo && documentInfo?.document) {
      const document = allDocuments.filter(
        (document) => document._id === documentInfo?.document
      )[0];

      // // //"document", document);

      dispatch(
        updateDocument({
          documentDetails: document,
        })
      );
    }
  };

  useEffect(() => {
    findAndAddDocumentInfo();
  }, [documentInfo, id]);

  const [showSaveButton, setShowSaveButton] = useState(false);

  useEffect(() => {
    if (documentInfo) {
      if (documentNameInput !== documentInfo?.name) {
        setShowSaveButton(true);
      } else {
        setShowSaveButton(false);
      }
    }
  }, [documentNameInput]);

  const [saveDocumentNameLoading, setSaveDocumentNameLoading] = useState(false);

  const updateDocumentName = async () => {
    setSaveDocumentNameLoading(true);

    const response = await updateName({ name: documentNameInput, draftId: id });
    if (response.data) {
      setSaveDocumentNameLoading(false);

      dispatch(
        updateDocument({
          documentInfo: {
            name: documentNameInput,
            ...documentInfo,
          },
        })
      );
    } else {
      setSaveDocumentNameLoading(false);
    }
  };

  const [downloadLoading, setDownloadLoading] = useState(false);

  const downloadPdfClick = async (...props) => {
    // var opt = {
    //   margin: [0, 0],
    //   filename: "myfile.pdf",
    //   image: { type: "jpeg", quality: 0.98 },
    //   html2canvas: { scale: 2 },
    //   jsPDF: {
    //     orientation: "p",
    //     unit: "mm",
    //     format: "legal",
    //     putOnlyUsedFonts: true,
    //     floatPrecision: 16,
    //   },
    // };

    // // const formattedDocument = await formatDocumentForSigning();

    // if (documentInfo?.pdf) {
    //   // downloadPDF(...props);
    // } else {
    //   const element = document.getElementById("finalDocumentViewer");
    //   const clonedElement = element.cloneNode(true);

    //   const versionsDiv = clonedElement.querySelector(".versionsContainer");
    //   versionsDiv.remove();

    //   clonedElement.style.paddingLeft = "50px";
    //   clonedElement.style.paddingTop = "55px";
    //   clonedElement.style.paddingBottom = "55px";
    //   html2pdf().from(clonedElement).set(opt).toPdf().save();
    //   // downloadPDF(formattedDocument, props[1]);
    // }

    setDownloadLoading(true);

    const element = document.getElementById("finalDocumentViewer");
    const clonedElement = element.cloneNode(true);

    const versionsDiv = clonedElement.querySelector(".versionsContainer");
    versionsDiv.remove();

    const options = {
      method: "POST",
      url: "https://api.pdfendpoint.com/v1/convert",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer pdfe_live_80719f1de7c28412e5d1968f8de9faab8461",
      },
      data: JSON.stringify({
        html: clonedElement.innerHTML,
        css: `
          * {
            font-family: 'Helvetica Neue', Arial, sans-serif;
            color: #333;
          }
          body {
            margin: 0;
            padding: 0;
          }
          p {
            line-height: 1.6em;
            margin-bottom: 10px;
            font-size: 12px;
          }
          .finalDocumentViewerSubHeading {
            font-weight: bold;
            margin-top: 20px;
            font-size: 14px;
            color: #2c3e50;
          }
          .finalDocumentViewerTitle {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 20px;
            text-align: center;
            color: #34495e;
          }
          .finalDocumentViewerContent {
            padding: 20px;
            border: 1px solid #ddd;
            border-radius: 5px;
            background-color: #f9f9f9;
          }
          .finalDocumentViewerHeader {
            border-bottom: 2px solid #34495e;
            padding-bottom: 10px;
            margin-bottom: 20px;
          }
          .finalDocumentViewerFooter {
            border-top: 2px solid #34495e;
            padding-top: 10px;
            margin-top: 20px;
            text-align: center;
            font-size: 10px;
            color: #7f8c8d;
          }
        `,
        sandbox: false,
        orientation: "vertical",
        page_size: "A4",
        margin_top: "1.5cm",
        margin_bottom: "1.5cm",
        margin_left: "1.5cm",
        margin_right: "1.5cm",
        disable_html_validation: true,
      }),
    };

    axios
      .request(options)
      .then(function (response) {
        // // console.log(response.data);

        setDownloadLoading(false);

        downloadFile(response.data.data.url, `${documentInfo?.name}.pdf`);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const inputRef = useRef();

  const focusOnDocumentNameInput = () => {
    document.getElementById("finalDocumentHeaderTextInput").focus();
  };

  const [saveLoading, setSaveLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const saveDocument = async (documentArrayElements, redirect = true) => {
    setSaveLoading(true);

    let formattedDocumentElementsArray = returnFormattedDocumentsArray();

    //"FORMATTED ARRAY: ", formattedDocumentElementsArray);

    //"document info:", documentInfo);

    const response = await saveDraftDocumentAPI({
      draft_id: id,
      content: formattedDocumentElementsArray,
    });

    // add new version to redux document_drafts
    dispatch(
      updateDocument({
        documentInfo: {
          ...documentInfo,
          document_drafts: [
            ...documentInfo?.document_drafts,
            {
              version: documentInfo?.document_drafts?.length,
              content: formattedDocumentElementsArray,
            },
          ],
        },
        unsavedChanges: false,
      })
    );

    if (redirect)
      navigate(
        `/document/${id}/draft/${documentInfo?.document_drafts?.length + 1}`
      );

    if (response.data) {
      setSaveLoading(false);
      setOpen(true);

      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  };

  useEffect(() => {
    //"document changed", documentInfo?.document_drafts);
  }, [documentInfo]);

  const [updatingElementId, setUpdatingElementId] = useState(null);
  const [updatingTextStream, setUpdatingTextStream] = useState("");

  const [updatingElementData, setUpdatingElementData] = useState(null);

  const [showQuickOverview, setShowQuickOverview] = useState(false);

  const [streamStatus, setStreamStatus] = useState("");

  const [overviewData, setOverviewData] = useState(documentInfo?.overview);

  let newElement = null;
  let localOverview = [];

  async function readStream(stream, editor) {
    const populateStreams = (object) => {
      if (newElement && object.data?.delta?.content.includes("~")) {
        newElement += object.data?.delta?.content;
        newElement = newElement.replace("~", "");

        if (newElement.length !== 0 && newElement !== "\n") {
          localOverview.push(newElement);
          setOverviewData(localOverview);
        }

        // // console.log("NEW OVERVIEW: ", newElement);

        newElement = null;
        return;
      }
      // //object.data.delta);

      if (!newElement) {
        newElement = "";
        newElement += object.data?.delta?.content;
        return;
      }

      if (newElement) {
        newElement = newElement + object.data?.delta?.content;
        return;
      }
    };

    const reader = stream.getReader();
    while (true) {
      setStreamStatus("PENDING");

      const { done, value } = await reader.read();
      if (done) {
        setShowQuickOverview(true);
        saveDocumentOverview();
        setStreamStatus("DONE");

        return;
      }

      const textDecoder = new TextDecoder();
      try {
        const object = JSON.parse(textDecoder.decode(new Uint8Array(value)));

        if (object.data?.delta) {
          populateStreams(object);
        }
      } catch {
        const input = `${textDecoder.decode(new Uint8Array(value))}`;

        const objects = input
          .trim()
          .split("\n")
          .map((str) => {
            const object = JSON.parse(str);

            if (object.data?.delta) {
              populateStreams(object);
            }
          });
      }
    }
  }

  const getQuickOverview = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/draft/overview`,
      {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          api_key: "krishna",
          Authorization: user.token,
        },
        body: JSON.stringify({
          draftID: id,
        }),
      }
    );

    if (response.ok) {
      readStream(response.body);
    }
  };

  useEffect(() => {
    // console.log(documentInfo);

    if (documentInfo && documentInfo?.document_drafts?.length !== 0) {
      if (documentInfo?.overview && documentInfo?.overview.length !== 0) {
        setOverviewData(documentInfo?.overview);
      } else {
        getQuickOverview();
      }
    }
  }, [documentInfo]);

  const saveDocumentOverview = async () => {
    const response = await saveDocumentOverviewAPI({
      draftID: id,
      overview: localOverview,
    });
    if (response.data) {
      //
    }
  };

  const [perDocumentLoading, setPerDocumentLoading] = useState(false);

  const buyDocument = async () => {
    setPerDocumentLoading(true);

    // alert(
    //   JSON.stringify({
    //     documentId: documentInfo?.document,
    //     draftId: id,
    //   })
    // );

    const response = await getDocumentPaymentLinkAPI({
      documentId: documentInfo?.document,
      draftId: id,
    });

    if (response.data) {
      window.location.href = response.data.url;
      setPerDocumentLoading(false);

      //
    }
  };

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const [purchaseLoading, setPurchaseLoading] = useState(false);

  const generatePaymentLink = async (onSuccess) => {
    setPurchaseLoading(true);

    // //document.documentInfo
    const response = await upgradePlanAPI({
      redirectUrl: window.location.href,
    });

    if (response.data) {
      window.location.href = response.data.url;
      setPurchaseLoading(false);

      if (onSuccess) {
        onSuccess();
      }
    }
  };

  const [documentStatus, setDocumentStatus] = useState(null);

  const getDocumentPaidStatus = async () => {
    const response = await getDocumentPaidStatusAPI({
      documentId: documentInfo?.document,
    });

    if (response.data) {
      setDocumentStatus(response.data.status);
      if (
        response.data.status === "FREE_RESOURCE_AVAILABLE_FOR_ACCESS" &&
        documentInfo?.payment === false &&
        documentInfo?.document_drafts?.length !== 0
      ) {
        setShowUpgradeModal(true);
      } else if (response.data.status === "ACCESS_GRANTED_FOR_DOCUMENT") {
        setShowUpgradeModal(false);
      }
    }
  };

  const [submitLoading, setSubmitLoading] = useState(false);

  const submitForReview = async () => {
    setSubmitLoading(true);
    const response = await submitForReviewAPI({
      draftID: id,
    });

    if (response.data) {
      setSubmitLoading(false);
      window.location.href = response.data.url;
    }
  };

  useEffect(() => {
    if (documentInfo?.document) {
      getDocumentPaidStatus();
    }
  }, [documentInfo, id]);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const deleteDocument = async () => {
    setDeleteLoading(true);

    const response = await deleteDocumentDraftAPI({ id: id });

    if (response.data) {
      setDeleteLoading(false);
      setShowDeleteModal(false);

      navigate("/my-documents");
    }
  };

  const [showSidebarModal, setShowSidebarModal] = useState(false);

  const toggleSidebarModal = () => {
    setShowSidebarModal(!showSidebarModal);
  };

  const { token, ...userData } = useSelector((state) => state.user);

  return (
    <div className="finalDocumentContainer">
      {/* <h1>{JSON.stringify(documentInfo)}</h1> */}

      <>
        {/* {JSON.stringify(documentInfo?.lawyerReview)} */}

        {openSendForSignatureModal && (
          <Modal
            onClose={() => {
              setOpenSendForSignatureModal(false);
            }}
            HeaderIcon={BiSend}
            headerText={"Verify and Send the Signatures"}
            onClick={() => {
              sendForSignature();
            }}
            loading={signatureLoading}
            buttonText="Send for Signature"
            showButtonContainer={!documentInfo?.signature?.sentForSignature}
            headerClassName="signatureModalHeaderContent"
          >
            <div className="modalSignatureContent">
              {documentInfo?.signature?.sentForSignature ? (
                <>
                  <div className="modalSignatureSentForSignature">
                    <BsPatchCheckFill className="modalSignatureSentForSignatureIcon" />

                    <p className="modalSignatureSentForSignatureHeaderText">
                      Document sent! Check your email
                    </p>

                    <p className="modalSignatureSentForSignatureDescription">
                      Check your email for a link, click on that link to add
                      your signature to the document and you will be redirected
                      here.
                    </p>

                    <a
                      href="https://mail.google.com"
                      target="_blank"
                      className="checkEmailLink"
                      rel="noreferrer"
                    >
                      Go to Mail
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <p className="signatureVerifyText">Details of Signers</p>

                  <div className="modalSignatureDetails">
                    <div className="modalSignatureDetailsHeader">
                      <p className="modalSignatureNameHeader">Name</p>
                      <p className="modalSignatureEmailHeader">Email</p>
                    </div>

                    {documentInfo?.signature
                      ? documentInfo?.signature.signers.map((signature) => (
                          <>
                            <div className="modalEachSignatureDetail">
                              <p className="modalSignatureName">
                                {signature.name}
                              </p>
                              <p className="modalSignatureEmail">
                                {signature.email}
                              </p>
                            </div>
                          </>
                        ))
                      : signatures.map((signature) => (
                          <>
                            <div className="modalEachSignatureDetail">
                              <p className="modalSignatureName">
                                {signature.details.name}
                              </p>
                              <p className="modalSignatureEmail">
                                {signature.details.email}
                              </p>
                            </div>
                          </>
                        ))}
                  </div>
                </>
              )}
            </div>
          </Modal>
        )}

        {/* {JSON.stringify(overviewData)} */}

        {streamStatus === "PENDING" && (
          <div className="finalDocumentAlertInfo">
            <IoWarning className="finalDocumentAlertInfoIcon" />

            <p className="finalDocumentAlertInfoText">
              Don't refresh or close the browser, a Quick overview of this
              document is being created.
            </p>

            <Oval
              height={20}
              width={20}
              color="#1252f3"
              wrapperStyle={{}}
              visible={true}
              ariaLabel="oval-loading"
              secondaryColor="#1252f320"
              wrapperClass="finalDocumentAlertInfoLoadingIcon"
              strokeWidth={5}
              strokeWidthSecondary={5}
            />
          </div>
        )}

        <div className="finalDocumentHeader">
          <FiChevronLeft
            className="finalDocumentHeaderIcon"
            onClick={() => navigate("/my-documents")}
          />

          <div className="finalDocumentHeaderLeft">
            {/* <p className="finalDocumentHeaderText">{documentDetails?.title}</p> */}
            <span
              className="finalDocumentHeaderTextInput"
              id="finalDocumentHeaderTextInput"
              contentEditable="true"
              onInput={(e) => {
                setDocumentNameInput(e.target.textContent);
              }}
              ref={inputRef}
              defaultValue={documentNameInput}
            >
              {documentInfo?.name}
            </span>
            {!showSaveButton && (
              <AiFillEdit
                onClick={() => focusOnDocumentNameInput()}
                className="documentNameInputChangeIcon"
              />
            )}
            {showSaveButton && (
              <button
                className={`documentNameSaveButton ${
                  saveDocumentNameLoading && "disabledButton"
                }`}
                onClick={() => updateDocumentName()}
                disabled={saveDocumentNameLoading}
              >
                <p className="documentNameSaveButtonText">Save</p>
              </button>
            )}
            <Tooltip
              text={`Document was created on ${formatDate(
                documentInfo?.createdAt
              )}, ${new Date(documentInfo?.createdAt).toLocaleTimeString()}`}
            >
              <p className="finalDocumentCreatedDate">
                (Created on {formatDate(documentInfo?.createdAt)})
              </p>
            </Tooltip>

            <div
              className={`versionIconContainer ${
                (!versionId ||
                  documentInfo?.document_drafts?.length - 1 ===
                    parseInt(versionId) - 1) &&
                "latestVersionIconContainer"
              }`}
            >
              <p className="versionText">
                {versionId
                  ? parseInt(versionId) - 1 ===
                    documentInfo?.document_drafts?.length - 1
                    ? "Latest"
                    : `v${versionId}`
                  : "Latest"}
              </p>
            </div>

            {documentInfo?.document_drafts?.length === 0 && (
              <>
                <Oval
                  height="18"
                  width="18"
                  radius={4}
                  strokeWidth={5}
                  color="#1253f3"
                  secondaryColor="#1253f360"
                  ariaLabel="puff-loading"
                  wrapperStyle={{}}
                  wrapperClass="documentLoading"
                  visible={true}
                />
                <p className="documentLoadingText">
                  Document is being created...
                </p>
              </>
            )}
          </div>

          {/* {JSON.stringify()} */}

          {documentInfo && (
            <div className="finalDocumentHeaderButtons">
              <>
                {/* {JSON.stringify(documentInfo?.document_drafts?.length)} */}
                {/* 
                {documentInfo.status === "LIMITED_DOCUMENT_DRAFTED" && (
                  <Tooltip text="You only have a limited version of the document available. Re-draft this document to get the full version.">
                    <button className="redraft_button" onClick={() => {
                      if (documentInfo.status === 'LIMITED_DOCUMENT_DRAFTED') {
                        
                      }
                    }}>
                      <ResetIcon className="redraft_icon" />
                      <p className="redraft_text">Redraft document</p>
                    </button>
                  </Tooltip>
                )} */}

                {documentInfo?.document_drafts?.length !== 0 && (
                  <button
                    className={`saveButton signButton downloadButton ${
                      downloadLoading && "disabledButton"
                    }`}
                    disabled={
                      downloadLoading ||
                      documentInfo?.document_drafts?.length === 0
                    }
                    onClick={() => {
                      downloadPdfClick(
                        documentInfo?.document_drafts[
                          documentInfo?.document_drafts?.length - 1
                        ],
                        documentInfo?.name
                      );
                    }}
                  >
                    <HiOutlineDownload className="saveButtonIcon" />
                    <div className="saveButtonText">Download as PDF</div>

                    {downloadLoading && (
                      <Oval
                        height={14}
                        width={14}
                        color="#191f2f70"
                        wrapperStyle={{}}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#191f2f20"
                        wrapperClass="saveButtonLoadingIcon"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    )}
                  </button>
                )}

                {showDeleteModal && (
                  <RadixModal
                    title="Permanent Delete"
                    description="This change cannot be reversed. Confirm if you want to proceed."
                    buttonText="Yes, Permanently delete this Document"
                    openModal={showDeleteModal}
                    setOpenModal={setShowDeleteModal}
                    className="deleteDocumentModal"
                    onClick={() => {
                      deleteDocument();
                    }}
                    loading={deleteLoading}
                  >
                    <div className="deleteDocumentModalContainer">
                      <p className="deleteDocumentText">
                        Once you permanently delete this document, you will not
                        able be able access this document again.{" "}
                        <b>Confirm if you want to proceed with this.</b>
                      </p>
                    </div>
                  </RadixModal>
                )}

                <button
                  className={`saveButton signButton downloadButton deleteButton`}
                  disabled={
                    downloadLoading ||
                    documentInfo?.document_drafts?.length === 0
                  }
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                >
                  <HiOutlineDownload className="saveButtonIcon" />
                  <div className="saveButtonText">Delete</div>

                  {downloadLoading && (
                    <Oval
                      height={14}
                      width={14}
                      color="#191f2f70"
                      wrapperStyle={{}}
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="#191f2f20"
                      wrapperClass="saveButtonLoadingIcon"
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  )}
                </button>

                {!documentInfo?.signature?.sentForSignature && (
                  <div className="downloadLine"></div>
                )}

                {!documentInfo?.signature?.sentForSignature && (
                  <>
                    <Toast.Provider swipeDirection="right">
                      <button
                        onClick={() => saveDocument()}
                        className={`saveButton ${
                          saveLoading && "disabledButton"
                        }`}
                        disabled={saveLoading}
                      >
                        <BiSave className="saveButtonIcon" />
                        <div className="saveButtonText">
                          {saveLoading ? "Saving" : "Save"}
                        </div>

                        {saveLoading && (
                          <Oval
                            height={10}
                            width={10}
                            color="white"
                            wrapperStyle={{ marginLeft: 5 }}
                            visible={true}
                            ariaLabel="oval-loading"
                            secondaryColor="#ffffff7b"
                            strokeWidth={5}
                            strokeWidthSecondary={5}
                          />
                        )}
                      </button>

                      <Toast.Root
                        className="ToastRoot"
                        open={open}
                        onOpenChange={setOpen}
                      >
                        <div className="toastContainer">
                          <div className="toastIcon">
                            <BiCheck className="documentSavedIcon" />
                          </div>

                          <div className="toastContent">
                            <Toast.Title className="ToastTitle">
                              Document Saved
                            </Toast.Title>
                            <Toast.Description asChild>
                              <p className="ToastDescription">
                                <b>{documentInfo?.name}</b> was saved
                                successfully.
                              </p>
                            </Toast.Description>
                            <Toast.Action
                              className="ToastAction"
                              asChild
                              altText="Goto schedule to undo"
                            >
                              <button
                                onClick={() => setOpen(false)}
                                className="Button small green"
                              >
                                <IoClose />
                              </button>
                            </Toast.Action>
                          </div>
                        </div>
                      </Toast.Root>
                      <Toast.Viewport className="ToastViewport" />
                    </Toast.Provider>

                    {/* {documentInfo?.signature?.sentForSignature ||
                    !allSignaturesFinalised() ? (
                    <SendForSignature showTooltip={true} />
                  ) : (
                    <SendForSignature />
                  )} */}
                  </>
                )}
              </>
            </div>
          )}
        </div>

        <div className="finalDocumentRight">
          <FinalDocumentContext>
            <DocumentViewer
              updatingElementId={updatingElementId}
              updatingTextStream={updatingTextStream}
              setUpdatingElementId={setUpdatingElementId}
              setUpdatingTextStream={setUpdatingTextStream}
              updatingElementData={[
                updatingElementData,
                setUpdatingElementData,
              ]}
              documentInfo={documentInfo}
              documentStatus={documentStatus}
            />

            {documentInfo?.document_drafts?.length !== 0 && (
              <>
                <FinalDocumentChat
                  chatSectionProps={chatSectionProps}
                  toggleChat={toggleChat}
                  chatInfo={chatInfo}
                  updatingElementId={updatingElementId}
                  updatingTextStream={updatingTextStream}
                  setUpdatingElementId={setUpdatingElementId}
                  setUpdatingTextStream={setUpdatingTextStream}
                  updatingElementData={[
                    updatingElementData,
                    setUpdatingElementData,
                  ]}
                />
              </>
            )}

            <div className="finalDocumentRightSidebar">
              <div className="finalDocumentRightSidebarHeader">
                <AiFillInfoCircle className="finalDocumentRightSidebarHeaderIcon" />
                <p className="finalDocumentRightSidebarHeaderText">Details</p>
              </div>

              {documentInfo?.document_drafts?.length !== 0 && (
                <div className="quickOverviewSection">
                  {documentInfo?.document_drafts?.length === 0 && (
                    <p className="quickOverviewLoadingInfo">
                      This will be generated once your document is created
                    </p>
                  )}

                  <div
                    className="quickOverviewHeaderText"
                    onClick={() => {
                      if (documentInfo?.document_drafts?.length !== 0) {
                        setShowQuickOverview(!showQuickOverview);
                      }
                    }}
                  >
                    <div className="quickOverviewHeaderTop">
                      <MdViewDay className="quickOverviewHeaderTextIcon" />
                      <div className="quickOvervhewHeaderTextMain">
                        Quick overview
                      </div>
                    </div>

                    {streamStatus === "PENDING" ? (
                      <Oval
                        height={20}
                        width={20}
                        color="#1252f3"
                        wrapperStyle={{}}
                        visible={true}
                        ariaLabel="oval-loading"
                        secondaryColor="#1252f320"
                        wrapperClass="quickOverviewHeaderCloseIcon"
                        strokeWidth={5}
                        strokeWidthSecondary={5}
                      />
                    ) : (
                      <BiChevronDown className="quickOverviewHeaderCloseIcon" />
                    )}

                    <p className="quickOverviewHeaderDescription">
                      This helps you check whether the points included in this
                      document is relevant and suits the decisions you made.
                    </p>
                  </div>

                  {showQuickOverview && (
                    <div className="quickOverviewContent">
                      <div className="quickOverviewSectionScroll"></div>
                      {/* {JSON.stringify(overviewData)} */}

                      {overviewData &&
                        overviewData.map((eachOverview, index) => (
                          <div className="eachQuickOverviewContent ">
                            <p className="quickOverviewNumber">{index + 1}</p>
                            <div className="quickOverviewText">
                              {eachOverview}
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </FinalDocumentContext>
        </div>
      </>

      {/* <div className="documentViewerLoadingContainer">
          <DocumentViewerLoader text="Loading Document" size="large" />
        </div> */}
    </div>
  );
};
